/**
 * 法币提现工具类
 */
import { baseCommonStore } from '@/store/common'
import { CurrencyModeErationEnum } from '@/constants/common'
import { formatCurrency } from '@/helper/decimal'
import { baseAssetsStore } from '@/store/assets'
import { faitAmount } from '@/helper/assets'

/** 根据法币的切换获取金额方法 */
export const selectfaitAmount = (balance, rate, coinPrecision, isThousandths = true) => {
  return faitAmount(balance, Number(rate), true, coinPrecision, isThousandths)
}

export function singleCurrencyMethod(currentValue, balance, offset, selectCurrencyAmount) {
  const { merchantInfo } = baseAssetsStore.getState()
  return `${
    currentValue !== merchantInfo?.symbol ? `${selectCurrencyAmount()} ${currentValue} ≈ ` : ''
  } ${formatCurrency(balance?.balance || 0, offset, false)} ${balance?.cryptCode || ''}`
}

/** 法币提现可提展示 */
export function cashWithdrawalDisplayMethod(
  currentValue,
  balanceData,
  offset,
  defaultValueCurrency,
  selectCurrencyAmount,
  areaCurrencyData
) {
  const { localeInfo } = baseCommonStore.getState()
  const { merchantInfo } = baseAssetsStore.getState()
  switch (localeInfo?.currencyMode) {
    case CurrencyModeErationEnum.multiCurrency:
      return `${
        defaultValueCurrency?.fiatCode !== currentValue
          ? `${selectfaitAmount(
              balanceData?.balance > 0 ? areaCurrencyData?.amount : 0,
              balanceData?.rate,
              defaultValueCurrency?.coinPrecision
            )} ${defaultValueCurrency?.fiatCode || ''} ≈ `
          : ''
      } ${formatCurrency(balanceData?.balance || 0, defaultValueCurrency?.coinPrecision, false)} ${currentValue || ''}`
    case CurrencyModeErationEnum.singleCurrency:
      return singleCurrencyMethod(currentValue, balanceData, offset, selectCurrencyAmount)
    case CurrencyModeErationEnum.singleFiatCurrency:
      return singleCurrencyMethod(currentValue, balanceData, merchantInfo?.coinPrecision, selectCurrencyAmount)
    default:
      return singleCurrencyMethod(currentValue, balanceData, offset, selectCurrencyAmount)
  }
}
